import { Box, HStack, SimpleGrid, VStack } from '@chakra-ui/react'
import { HeadFC } from 'gatsby'
import React from 'react'
import AppCard from '../components/apps/AppCard'
import SubHeading from '../components/typography/SubHeading'
import Layout from '../layout/Layout'
import lists from '../../content/list.json'
import { AppList } from '../components/types/ListTypes'
import SEO from '../components/SEO'

const all: AppList[] = lists
    .filter((list) => list.pinned)
    .map((list) => {
        return {
            title: list.title,
            apps: require(`../../content/lists/${list.listName}.json`),
        }
    })

interface Props {}

const Page: React.FC<Props> = () => {
    return (
        <Layout fullWidth>
            <VStack
                p={6}
                w="full"
                alignItems="flex-start"
                spacing={6}
                bg="blackAlpha.50"
            >
                {all.map((list, index) => {
                    return (
                        <Box key={index} as="section">
                            <SubHeading
                                fontSize="xl"
                                textTransform="uppercase"
                                mb={6}
                            >
                                {list.title}
                            </SubHeading>
                            <SimpleGrid
                                spacing={6}
                                columns={{ base: 1, sm: 2, md: 3, lg: 4 }}
                            >
                                {list.apps.map((app: any) => {
                                    return (
                                        <Box key={app.appId}>
                                            <AppCard data={app} />
                                        </Box>
                                    )
                                })}
                            </SimpleGrid>
                        </Box>
                    )
                })}
            </VStack>
        </Layout>
    )
}

export default Page

export const Head: HeadFC = (props) => {
    const { location } = props

    const description = 'Latest iPhone/iPad App News and Reviews'
    const summary = 'Latest iPhone/iPad App News and Reviews'
    const title = 'Latest iPhone/iPad App News and Reviews'

    return (
        <SEO
            description={description}
            location={location.pathname}
            summary={summary}
            title={title}
        />
    )
}
